/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */


/* Core CSS required for Ionic components to work properly */

@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */

@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */

@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

/* set fonts */

@font-face {
    font-family: 'Nunito';
    src: url('/assets/fonts/Nunito/static/Nunito-Bold.ttf') format('truetype'); //url('../fonts/Lato-Light.eot') format('embedded-opentype'), url('../fonts/Lato-Light.woff') format('woff'),
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Nunito';
    src: url('/assets/fonts/Nunito/static/Nunito-Medium.ttf') format('truetype'); //url('../fonts/Lato-Light.eot') format('embedded-opentype'), url('../fonts/Lato-Light.woff') format('woff'),
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Nunito';
    src: url('/assets/fonts/Nunito/static/Nunito-Regular.ttf') format('truetype'); //url('../fonts/Lato-Light.eot') format('embedded-opentype'), url('../fonts/Lato-Light.woff') format('woff'),
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Nunito';
    src: url('/assets/fonts/Nunito/static/Nunito-Light.ttf') format('truetype'); //url('../fonts/Lato-Light.eot') format('embedded-opentype'), url('../fonts/Lato-Light.woff') format('woff'),
    font-weight: 300;
    font-style: normal;
}

:root {
    --ion-background-color: #FFFFFF;
}

.hotfix.header-md::after {
    /* Removes the toolbar shadow. */
    background: none;
}

.searchbar-input-container.sc-ion-searchbar-ios {
    height: 45px;
}

ion-datetime {
    max-width: 100%;
}